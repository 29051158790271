import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import '@/assets/style/reset.css'
import '@/assets/style/common.css'
import 'animate.css/animate.min.css'
Vue.config.productionTip = false;
import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper)
import VWave from 'v-wave'
Vue.use(VWave)
import VueAnimateOnScroll from '@/utils/animateScroll';
Vue.use(VueAnimateOnScroll)

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
