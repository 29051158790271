<template>
  <div class="environment">
    <div class="banner">
      <img class="banner w-100" src="@/assets/images/environment/banner.jpg" />
    </div>
    <div class="main content-box">
      <div class="title-h1 text-align-c">
        <span class="font-30">开始在小鱼音乐学习吧</span>
      </div>
      <div class="main-lt">
        <div class="flex flex-align-c ">
          <img class="pic-item-l" src="@/assets/images/environment/img-1.png" />
          <div class="lt-txt item-txt">
            小鱼音乐学院的设计旨在打破传统学习给人的刻板印象，将时尚空间与音乐巧妙地结合，蓝色干净沉稳充满艺术感，就像畅游在蓝色海洋里学习。
          </div>
        </div>
        <div class="pic-item-list flex flex-align-c flex-btw">
          <img class="pic-item" src="@/assets/images/environment/pic-item-1.png" />
          <img class="pic-item" src="@/assets/images/environment/pic-item-2.png" />
          <img class="pic-item" src="@/assets/images/environment/pic-item-3.png" />
          <img class="pic-item" src="@/assets/images/environment/pic-item-4.png" />
        </div>
      </div>
      <div class="main-rt">
        <div class="flex flex-align-c ">
          <div class="rt-txt item-txt">
            小鱼音乐学院拥有优质录音棚声场设计和悬浮式的空间结构，与日常教学区域完美隔音，给你五星级的学习体验。
          </div>
          <img class="pic-item-l" src="@/assets/images/environment/img-2.png" />
          
        </div>
        <div class="pic-item-list flex flex-align-c flex-btw">
          <img class="pic-item" src="@/assets/images/environment/pic-item2-1.png" />
          <img class="pic-item" src="@/assets/images/environment/pic-item2-2.png" />
          <img class="pic-item" src="@/assets/images/environment/pic-item2-3.png" />
          <img class="pic-item" src="@/assets/images/environment/pic-item2-4.png" />
        </div>
      </div>
      <div class="main-lt">
        <div class="flex flex-align-c ">
          <img class="pic-item-l" src="@/assets/images/environment/img-3.png" />
          <div class="lt-txt item-txt">
            整体的设计装饰细节都结合了音乐文化元素，感受到音乐的重要意义，音乐融入生活，不仅是享受音乐带来的快乐，更多的是看到自己演唱进步和提升音乐素养带来的个人成就感。
          </div>
        </div>
        <div class="pic-item-list flex flex-align-c flex-btw">
          <img class="pic-item" src="@/assets/images/environment/pic-item3-1.png" />
          <img class="pic-item" src="@/assets/images/environment/pic-item3-2.png" />
          <img class="pic-item" src="@/assets/images/environment/pic-item3-3.png" />
          <img class="pic-item" src="@/assets/images/environment/pic-item3-4.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.main{
  padding-top:80px;
  padding-bottom: 162px;
  .main-lt,.main-rt{
    width: 1080px;
    margin:120px auto 0;
    border-bottom: 1px solid #DAE3E6;
    padding-bottom: 40px;
  }
  .pic-item-l{
    width: 558px;
    height: 370px;
    border-radius: 4px;
  }
  .pic-item{
    width: 245px;
    height: 196px;
    border-radius: 4px;
  }
  .item-txt{
    width: 508px;
    background: #D5E5FD;
    border-radius: 4px;
    font-size:14px;  
    font-weight: 400;
    color: #000001;
    line-height:24px;
    padding: 30px 48px;
    position: relative;
   
  }
  .lt-txt{
    margin-left: 34px;
  }
  .rt-txt{
    margin-right: 34px;
  }
  .lt-txt::before{
    content: '';
    position: absolute;
    left:-9px;
    top:50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-right: 9px solid #D5E5FD;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
  }
  .rt-txt::before{
    content: '';
    position: absolute;
    right:-9px;
    top:50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 9px solid #D5E5FD;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
  }
  .pic-item-list{
    margin-top: 80px;
  }
}
</style>