<template>
  <div class="move-tabs">
    <div class="tabs-wrapper" ref="tabsWrapper" @mouseover="stopScroll"
        @mouseout="startScroll">
      <ul ref="tab">
        <li v-for="(item, index) in tabs" :key="index">
          <div class="tab-item">
            <div
              class="tab-img-box"
              @mouseover="mouseoverhover(index)"
              @mouseleave="hoverIndex = -1"
            >
              <img class="tab-img" :src="item.src" />
              <div class="hover-box flex flex-column flex-center" v-if="(hoverIndex == index)">
                <div class="name">{{ item.name }}</div>
                <div class="intro">{{ item.intro }}</div>
              </div>
            </div>
            <div class="tab-name">
              {{ item.name }}
            </div>
            <div class="tab-intro">
              {{ item.intro1 }}
            </div>
            <div class="tab-intro">
              {{ item.intro2 }}
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import BScroll from "better-scroll";

export default {
  props: {
    data: Array,
  },
  data() {
    return {
      tabs: [],
      mX: 0,
      tabWidth: 267,
      hover: false,
      hoverIndex:-1,
      scrollW:0,
      scrollTime:null
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.tabs = this.data;
      this._initMenu();
      this.scrollAuto();
    });
  },
  methods: {
    stopScroll() {
      var target = this.$refs.tab;
      clearInterval(this.scrollTime);
    },
    //鼠标移开 ，接着滚动
    startScroll() {
      var target = this.$refs.tab;
      this.scrollW = target.offsetLeft; // 移开时记录向左移动的距离
      this.scrollAuto();
    },
    scrollAuto() {
      var that = this; //因为定时器里要使用vue对象时不能用this, 在定时器中的 this 代表
      var target = this.$refs.tab;
      var initLeft = 0;
      if (this.scrollW < 0) {
        initLeft = this.scrollW * -1;
      }
      //定时器
      this.scrollTime = setInterval(function () {
        initLeft++;
        if (initLeft >= target.offsetWidth / 2) {
          initLeft = 0;
        }
        target.style.left = "-" + initLeft + "px"; //获取不到translateX的值改用 left

        //target.style = 'transform: translateX(-'+ initLeft +'px)';
      }, 16);
    },
    mouseoverhover(index) {
      this.hoverIndex = index;
    },
    _initMenu() {
      const tabsWidth = this.tabWidth;
      const width = (this.tabs.length+1) * tabsWidth;
      this.$refs.tab.style.width = `${width}px`;
      this.$nextTick(() => {
        if (!this.scroll) {
          this.scroll = new BScroll(this.$refs.tabsWrapper, {
            scrollX: true,
            eventPassthrough: "vertical",
          });
        } else {
          this.scroll.refresh();
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.move-tabs {
  position: relative;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 400px;
}
.tabs-wrapper {
  height: 400px;
  width: 100%;
  box-sizing: border-box;
  // overflow: hidden;
  white-space: nowrap;
  
  ul{
    position: absolute;
    display: flex;
    flex-direction: row;
  }
}
.tab-item {
  float: left;
  width: 242px;
  height: 400px;
  padding: 10px;
  margin-right:25px;
}
.tab-img {
  width: 242px;
  height: 242px;
  border-radius: 50%;
}
.tab-name {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #000000;
  line-height: 34px;
  margin-top: 16px;
}
.tab-intro {
  font-size: 13px;
  font-weight: 400;
  color: #999999;
  line-height: 25px;
  text-align: center;
}
.tab-img-box {
  position: relative;
  width: 242px;
  height: 242px;
  border-radius: 50%;
}
.hover-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 242px;
  height: 242px;
  border-radius: 50%;
  z-index: 2;
  background: rgba(0, 0, 0, 0.74);
  color: #ffffff;
  .name{
    text-align: center;
    margin-bottom: 10px;
  }
  .intro {
    width: 200px;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    font-size: 12px;
    font-weight: 400;
    color: #ffffff;
    line-height: 25px;
    margin: 0 auto;
  }
}
</style>
