<template>
  <div id="app">
    <nav-view></nav-view>
    <div class="content">
      <router-view />
    </div>
    <footer>
      <footer-view></footer-view>
    </footer>
    
  </div>
</template>
<script>
import navView from "@/components/navTop";
import footerView from '@/components/footerView.vue'
export default {
  components: {
    navView,
    footerView
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif,Microsoft YaHei;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #E7EDFB;
}
.content{
  padding-top: 70px;

}
</style>
