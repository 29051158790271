import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import AboutView from "../views/AboutView.vue";
import EnvironmentView from "../views/EnvironmentView.vue";
import SystemView from "../views/SystemView.vue";
import TeacherView from "../views/TeacherView.vue";



Vue.use(VueRouter);

const routes = [
  {
    path: '',
    redirect: 'home',
  },
  {
    path: "/home",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    component:AboutView
  },{
    path: "/environment",
    name: "environment",
    component:EnvironmentView
  },{
    path: "/system",
    name: "system",
    component:SystemView
  },
  {
    path: "/teacher",
    name: "teacher",
    component:TeacherView
  },
];

const router = new VueRouter({
  scrollBehavior: () => ({ y: 0 }),
  routes,
});

export default router;
