<template>
  <div>
    <swiper class="swiper" :options="swiperOptions" ref="mySwiper">
      <swiper-slide v-for="(item, index) in bannerList" :key="index">
        <img style="width: 100%; height: 100%" :src="item.src" alt="" />
        <div class="btn-banner" v-if="index == 1" @click="showEwm(index)"></div>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  props: ["bannerList"],
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOptions: {
        autoplay: true,
        speed: 300,
        loop: true,
        observer: true,
        // 监测swiper父元素，如果有变化则初始化swiper
        observeParents: true,
        pagination: {
          el: ".swiper-pagination",
          // clickable设置为true时，点击分页器的指示点分页器会控制Swiper切换
          clickable: true,
          //修改分页器样式 ,
          bulletClass: "my-swiper-pagination-bullet",
          //激活样式
          bulletActiveClass: "my-bullet-active",
        },
      },
    };
  },
  methods: {
    showEwm(index) {},
  },
};
</script>

<style lang="scss">
.swiper {
  width: 100%;
  height: 766px;
  position: relative;
}
.btn-banner {
  position: absolute;
  bottom: 100px;
  left: 400px;
  width: 420px;
  height: 70px;
  cursor: pointer;
}

.my-swiper-pagination-bullet {
  width:10px;
  height:10px;
  display: inline-block;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5);
  margin-bottom: 30px !important;
  margin-right: 10px !important;
  margin-left: 10px !important;
}
.my-bullet-active {
  background: #ffffff;
  /*opacity: 0.6;*/
  width: 25px !important;
  height: 10px !important;
  border-radius: 5px;
}
</style>
