<template>
  <div class="home">
    <!-- 轮播图 -->
    <swiper-box :bannerList="bannerList"></swiper-box>
    <!-- 视频 -->
    <div class="content-box">
      <div class="title-h1-box">
        <img class="title-img" src="@/assets/images/home/title.png" />
      </div>
      <div class="home-title-2 text-align-c">
        <span class="font-30">【视频专区】</span>
      </div>
      <div class="video bg-cover">
        <video
          class="w-100"
          autobuffer=""
          autoloop=""
          muted
          loop="loop"
          autoplay="autoplay"
          playsinline=""
          poster="https://assets.shencut.com/video/video-edit-ad.jpg?ms=e2ed7e3b2a206638467f0c95fbf2ecd8"
        >
          <source
            src="https://assets.shencut.com/video/video-edit-ad.mp4?ms=e2ed7e3b2a206638467f0c95fbf2ecd8"
          />
        </video>
      </div>
    </div>
    <!-- 第一部分 -->
    <div class="content-box">
      <div class="title-h1-box">
        <img class="title-img" src="@/assets/images/home/title.png" />
      </div>
      <div class="home-title-2 text-align-c">
        <span class="font-30">【专业】</span><span class="font-add">+</span
        ><span class="font-30">【交流】</span><span class="font-add">+</span
        ><span class="font-30">【互动】</span>
      </div>
      <div class="home-title-3 text-align-c">全方位提升音乐综合素质</div>
      <div class="style-box flex  flex-ard">
        <div class="style-item" v-animate-onscroll="{ down: 'flipInY', up: 'flipOutY' }">
          <img
          v-animate-onscroll="'swing'"
            class="style-item-img"
            src="@/assets/images/home/style-item-1.png"
          />
          <p class="style-item-h1">独创专业教学体系</p>
          <p class="style-item-h2">
            剔除掉复杂的乐理知识，挑选出简谱和节奏，以歌曲教学和流行技巧为主，涵盖舞台表演、设备调控、后期调音、混音等。从入门到专业，培养全能型音乐人才。
          </p>
        </div>
        <div class="style-item">
          <img
            class="style-item-img"
            src="@/assets/images/home/style-item-2.png"
          />
          <p class="style-item-h1">多维一体的互动课程</p>
          <p class="style-item-h2">
            入学测试-制定专属学习计划-阶段测试-录播-直播-1v1辅导-4v1学习服务群全方位服务学员，将学习计划落到每月、没周、每天，让学员学习有方向不迷茫。</p>
        </div>
        <div class="style-item" >
          <img
            class="style-item-img"
            src="@/assets/images/home/style-item-3.png"
          />
          <p class="style-item-h1">多维一体的互动课程</p>
          <p class="style-item-h2">
            解答课、抖音直播、歌曲解析课、器乐分享课、练声打卡课、K歌交流课等课程全部真人直播，在课程和学习群中学员可以自由交流社交，同时内部举行小鱼学员比赛活动，全方位提升学员音乐综合素质。
          </p>
        </div>
      </div>
      <div class="gopage-btn" v-wave @click="gopage('/system')">课程体系</div>
      <div class="home-title-3 text-align-c">
        现开设声乐/键盘/吉他/创作各类专业课程
      </div>
      <!-- class-box -->
      <div class="class-box flex flex-wrap">
        <div class="class-item">
          <img
            class="class-item-img"
            src="@/assets/images/home/class-item-1.png"
          />
        </div>
        <div class="class-item class-item-text" v-wave="{
            color: '#0560ff',
            initialOpacity: 0.5,
            duration:1,
            easing: 'ease-in',
          }">
          <p class="class-item-h1">小鱼器乐</p>
          <p class="class-item-h2">XIAOYU MUSICAL INSTRUMENTS</p>
          <p class="class-item-h2">
            小鱼器乐目前主要架设《钢琴即兴伴奏》《吉他弹唱》两类课程，根据不同的需求，不同的基础，对应不同内容的学习，致力于帮助学员，学好乐器，更好的进行演奏演唱。
          </p>
          <img class="ip-bg" src="@/assets/images/home/ip.png" />
        </div>

        <div class="class-item">
          <img
            class="class-item-img"
            src="@/assets/images/home/class-item-2.png"
          />
        </div>
        <div class="class-item class-item-text" v-wave="{
            color: '#0560ff',
            initialOpacity: 0.5,
            duration:1,
            easing: 'ease-in',
          }">
          <p class="class-item-h1">流行唱法</p>
          <p class="class-item-h2">POP SINGING</p>
          <p class="class-item-h2">
            本课程是面向所有对流行演唱感兴趣的学员所开设，以系统、全面的课程体系辅以标准化的教学流程，以理论结合歌曲实际演唱的方式进行学员的教学。
          </p>
          <img class="ip-bg" src="@/assets/images/home/ip.png" />

        </div>
        <div class="class-item" >
          <img
            class="class-item-img"
            src="@/assets/images/home/class-item-3.png"
          />
        </div>
        <div
          class="class-item class-item-text"
          v-wave="{
            color: '#0560ff',
            initialOpacity: 0.5,
            duration:1,
            easing: 'ease-in',
          }"
        >
          <p class="class-item-h1">小鱼声乐</p>
          <p class="class-item-h2">XIAOYU'S VOCAL SYSTEM</p>
          <p class="class-item-h2">
            课程一共分为2个阶段《基础演唱班》和《进阶歌神班》从基础知识开始一直延伸到各类流行唱法和流行技巧，课程还会涉及到设备使用、舞台演唱表演、混音调音等等。
          </p>
          <img class="ip-bg" src="@/assets/images/home/ip.png" />

        </div>
      </div>
    </div>
    <!-- 第二部分 -->
    <div class="teacher">
      <div class="content-box">
        <div class="title-h1-box">
          <img class="title-img" src="@/assets/images/home/title.png" />
        </div>
        <div class="home-title-2 text-align-c">
          <span class="font-30">哆咪和你一次创造愉悦新生活！</span>
        </div>
        <div class="home-title-3 text-align-c">权威的教研团队</div>
      </div>
      <div class="teachers">
        <scroll-box :data="teacherList"></scroll-box>
      </div>
      <div class="gopage-btn" v-wave @click="gopage('/teacher')">教研天团</div>
    </div>
    <!-- 第三部分 -->
    <div class="home-content-3">
      <img class="w-100 home-bg-3" src="@/assets/images/home/home-bg-3.jpg" />
    </div>
    <!-- 第四部分 关于我们 -->
    <div class="content-box about-box">
      <div class="home-title-2 text-align-c">
        <span class="font-30">关于我们</span>
      </div>
      <div class="home-title-3 text-align-c">中创小鱼音乐简介</div>
      <div class="about-intro">
        中创小鱼在线教育是中创教育旗下的子品牌，专注打造集合素质教育、职场技能教育为一体的在线教育平台。拥有自己独立的经验丰富的教研团队，从用户需求出发，打造更加贴合用户群体需求的，更具实际运营的课程体系，从0基础入门到技能升级，从专业深挖、职场升级到能力变现。集专业的教研团队，讲师团队，课程制作团队，运营团队，商务部为一体的在线教育平台。
      </div>
      <div class="about-items flex flex-btw">
        <div class="about-item">
          <img src="@/assets/images/home/about-1.png" />
          <p class="about-item-txt">湖南省知名品牌</p>
        </div>
        <div class="about-item">
          <img src="@/assets/images/home/about-2.png" />
          <p class="about-item-txt">2021年度口碑影响力职业教育品牌</p>
        </div>
        <div class="about-item">
          <img src="@/assets/images/home/about-3.png" />
          <p class="about-item-txt">中国最具社会责任感企业</p>
        </div>
        <div class="about-item">
          <img src="@/assets/images/home/about-4.png" />
          <p class="about-item-txt">电子信息协会理事单位</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import SwiperBox from "@/components/swiperBox.vue";
import ScrollBox from "@/components/scrollBox.vue";
import { teachersList } from "@/utils/teacherData.js";
export default {
  name: "HomeView",
  components: {
    SwiperBox,
    ScrollBox,
  },
  data() {
    return {
      bannerList: [
        {
          src: require("@/assets/images/banner1.jpg"),
        },
        {
          src: require("@/assets/images/banner2.jpg"),
        },
      ],
      teacherList: teachersList,
    };
  },
  methods: {
    gopage(val) {
      this.$router.push(val);
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  background: #e7edfb;
}
.video {
  width: 990px;
  // height: 575px;
  margin: 50px auto 0;
}
.home-title-2 {
  margin-top: 20px;
  margin-bottom:20px;
}
.home-title-3 {
  font-size: 24px;
  font-weight: 400;
  color: #262b2d;
  line-height: 34px;
  letter-spacing:1px;
}
.style-box {
  margin-top: 57px;
  .style-item {
    width: 330px;
    text-align: center;
    .style-item-img {
      width: 201px;
      height: 222px;
    }
    .style-item-h1 {
      font-size: 22px;
      color: #262b2d;
      line-height: 34px;
      position: relative;
      top: -56px;
    }
    .style-item-h2 {
      font-size: 16px;
      color: #666666;
      line-height: 25px;
      text-align: left;
      position: relative;
      top: -34px;
    }
  }
}
.gopage-btn {
  width: 130px;
  height: 38px;
  background: linear-gradient(269deg, #0560ff, #0060ff);
  border-radius: 25px;
  margin:40px auto 50px;
  color: #ffffff;
  line-height: 38px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}
.class-box {
  width: 1080px;
  margin:40px auto 0;
  .class-item {
    width: 360px;
    height: 233px;
    background: linear-gradient(180deg, #c4e0ff, #c4e0ff, #7ab9ff);
    .class-item-img {
      width: 100%;
      height: 100%;
    }
  }
  .class-item-text {
    padding: 36px;
    box-sizing: border-box;
    position: relative;
    .class-item-h1 {
      font-size: 19px;
      color: #0460ff;
      line-height: 29px;
      margin-bottom: 18px;
      font-weight:700;
    }
    .class-item-h2 {
      font-size: 12px;
      font-weight: 400;
      color: #2d3137;
      line-height: 22px;
    }
    .ip-bg{
      position: absolute;
      bottom: 0;
      left: 20px;
      width: 155px;
    }
  }
}
.teachers {
  margin-top: 58px;
}
.home-content-3 {
  position: relative;
}
.about-box {
  padding-bottom: 129px;
  .about-intro {
    font-size: 16px;
    font-weight: 400;
    color: #666666;
    line-height: 28px;
    margin: 64px 0 60px;
  }
  .about-item {
    text-align: center;
    width: 286px;
    height: 255px;
    border: 2px solid #d4def4;
    border-radius: 10px;
    padding-top: 21px;
    box-sizing: border-box;
    img {
      width: 263px;
      height: 180px;
      margin-bottom: 20px;
    }
  }
  .about-item:hover {
    border: 2px solid #3e91fe;
  }
}

</style>
