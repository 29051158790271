export const teachersList = [{
        name: "舒雅",
        intro1: "泰国格乐大学音乐学学位进修",
        intro2: "师从著名声乐教育家杨立岗教授",
        intro: '原创音乐人，发布QQ音乐、酷狗音乐、网易云音乐6首原创歌曲，代表作《和你》,获“中国梦”第十届全国文化艺术展演本科组声乐“一等奖”CCTV湖南艺术节声乐比赛金奖获2018年国际中韩声乐大赛“二等奖”获得“武汉音乐学院”声乐等级证书',
        src: require("@/assets/images/teacher/sya.png"),
    },
    {
        name: "万里",
        intro: "多年乐队演出经验，担任乐队主音吉他手,获得百歌颂华合唱比赛混声组金奖,2021年10月获得娄底好声音冠军",
        intro1: "3年的声乐教学经验",
        intro2: "擅长流行演唱，吉他弹唱",
        src: require("@/assets/images/teacher/wli.png"),
    },
    {
        name: "永琪",
        intro: "2020年参加由中国大众音乐协会举办的全国音乐专业比赛三等奖,2021年参加'西部放歌'青年组银奖,曾在中国中东欧青年艺术人才培训与实践中心担任声乐教师",
        intro1: "4年声乐教学经验",
        intro2: "擅长民族声乐，音乐剧",
        src: require("@/assets/images/teacher/yqi.png"),
    },
    {
        name: "鱼晖",
        intro: "2019年打造流行声乐精品小课，三个月内销量突破10w 2021年带领团队研发课程《TVT声乐教学》 2022年加入中创小鱼课程研发团队，担任声乐课程研发总监 2022年加入小鱼音乐，评选为 “内部讲师培训师”",
        intro1: "从事在线教育行业5年",
        intro2: "累计服务学员3000+",
        src: require("@/assets/images/teacher/yhui.png"),
    },
    {
        name: "梦雪",
        intro: "曾先后任职于伯乐音乐与羊驼教育担任声乐高级讲师,专攻嗓音科学、欧美sls发声体系,网易云音乐人官网方认证,曾获得16届奥尔夫音乐大赛“优秀奖”",
        intro1: "5年声乐教学经验",
        intro2: "擅长通俗、戏腔、流行、R&B",
        src: require("@/assets/images/teacher/mxue.png"),
    },
    {
        name: "琳恩",
        intro: "擅长声乐教学，舞台形体教学，舞台表现力教学,2018岳阳青年歌手大赛二等奖,2019年大连爱国主题文艺宣传片乐手演员",
        intro1: "3年声乐教学经验",
        intro2: "擅长声乐教学，舞台形体教学",
        src: require("@/assets/images/teacher/len.png"),
    },
    {
        name: "月半",
        intro: "现任小鱼音乐课堂声乐课程高级研发导师。 与中央民乐团进行签约多次参加全国各地大剧院、艺术中心的演出. 网易云，酷狗，QQ音乐平台认证歌手，音乐人",
        intro1: "从事声乐教育8年",
        intro2: "进修于中国音乐学院",
        src: require("@/assets/images/teacher/ypang.png"),
    },
    {
        name: "西门",
        intro: "国内线上声乐创始人及理查德流行音乐培训机构创始人之一,现任小鱼音乐学院，金牌讲师兼研发主任，《小鱼流行演唱系统班》主讲,2021年带领团队研发出新型产品《音乐演唱全能班》",
        intro1: "从事在线教育行业5年",
        intro2: "进修于美国MI现代音乐学院",
        src: require("@/assets/images/teacher/xmeng.png"),
    },
    {
        name: "亦白",
        intro: "在十六届全国青少年才艺大赛获声乐类金奖。 在五星级社团内担任文艺部部长，策划并实施周年庆舞台表演,被邀请红河洲哈尼族彝族自治州建州六十周年庆《奔腾红河》文艺演出中担任演员",
        intro1: "5年声乐教学经验",
        intro2: "仰恩大学音乐系进修",
        src: require("@/assets/images/teacher/ybai.png"),
    },
    {
        name: "越越",
        intro: "师从世界著名男高音歌唱家，“中国雅歌”倡导者范进马教授。曾被中国音乐家协会提名“中国雅歌最美传承者,现但任小鱼音乐高级辅导老师。2020湖北省大艺术节玄鸟合唱团合唱一等奖,担任湖北省大学生艺术展演声乐专业组评委老师,第七届“长江杯”声乐专业组一等奖",
        intro1: "从事音乐在线教育3年",
        intro2: "华中师范大学音乐系",
        src: require("@/assets/images/teacher/yyue.png"),
    },
    {
        name: "四月",
        intro: "毕业于沈阳音乐学院,2020年12月加入中创小鱼音乐学院担任金牌声乐导师,教学的学员在抖音，快手，视频号等平台都有高额粉丝量",
        intro1: "8年演唱教学经验",
        intro2: "精通流行各类风格唱法",
        src: require("@/assets/images/teacher/syue.png"),
    },
    {
        name: "达尔",
        intro: "湖南省三独比赛19 20 21三年连续获得金奖,2019年演奏 爵士乐《Watermelon man》获得中国人民共和国教育部举办的大学生艺术展演比赛中获得一等奖",
        intro1: "湖南第一师范学院音乐系",
        intro2: "丰富的现场经验",
        src: require("@/assets/images/teacher/der.png"),
    }

]