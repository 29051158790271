<template>
  <div class="teacher">
    <div class="banner">
      <img class="banner w-100" src="@/assets/images/teacher/banner.jpg" />
    </div>
    <div class="main content-box">
      <div class="title-h1 text-align-c">
        <span class="font-30">团队师资</span>
      </div>
      <div class="teacher-box flex flex-align-c flex-wrap flex-btw">
        <div
          class="teacher-items"
          v-for="(item, index) in teachersList"
          :key="index"
        >
          <div class="tab-item">
            <div
              class="tab-img-box"
              @mouseover="mouseoverhover(index)"
              @mouseleave="hoverIndex = -1"
            >
              <img class="tab-img" :src="item.src" />
              <div
                class="hover-box flex flex-column flex-center"
                v-if="hoverIndex == index"
              >
                <div class="name">{{ item.name }}</div>
                <div class="intro">{{ item.intro }}</div>
              </div>
            </div>
            <div class="tab-name">
              {{ item.name }}
            </div>
            <div class="tab-intro">
              {{ item.intro1 }}
            </div>
            <div class="tab-intro">
              {{ item.intro2 }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { teachersList } from "@/utils/teacherData.js";
export default {
  data() {
    return {
      teachersList: teachersList,
      hoverIndex: -1,
    };
  },
  methods: {
    mouseoverhover(index) {
      this.hoverIndex = index;
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 1100px;
  padding-top: 74px;
  padding-bottom: 100px;
}
.teacher-box {
  margin-top: 92px;
  .teacher-items {
    margin-right: 44px;
    margin-bottom: 77px;
    .tab-item {
      width: 242px;
      
    }
    .tab-img {
      width: 242px;
      height: 242px;
      border-radius: 50%;
    }
    .tab-name {
      text-align: center;
      font-size: 16px;
      font-weight: bold;
      color: #000000;
      line-height: 34px;
      margin-top:16px;
    }
    .tab-intro {
      font-size: 13px;
      font-weight: 400;
      color: #999999;
      line-height: 25px;
      text-align: center;
    }
    .tab-img-box {
      position: relative;
      width: 242px;
      height: 242px;
      border-radius: 50%;
    }
    .hover-box {
      position: absolute;
      top: 0;
      left: 0;
      width: 242px;
      height: 242px;
      border-radius: 50%;
      z-index: 2;
      background: rgba(0, 0, 0, 0.74);
      color: #ffffff;
      .name {
        text-align: center;
        margin-bottom: 10px;
      }
      .intro {
        width: 200px;
        overflow: hidden;
        white-space: normal;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        font-size: 12px;
        font-weight: 400;
        color: #ffffff;
        line-height: 25px;
        margin: 0 auto;
      }
    }
  }
  .teacher-items:nth-child(4n){
    margin-right:0;
  }
}
.teacher-box::after {
    content: '';
    flex: auto;    /* 或者flex: 1 */
}
</style>
