<template>
  <div class="footer">
    <img class="w-100" src="@/assets/images/footer.jpg" />
    <div class="footer-content content-box flex">
      <div class="footer-item">
        <div class="footer-h1">客户热线</div>
        <div class="h1-hr"></div>
        <div class="item-mt-20 tel-box flex flex-align-c">
          <img class="tel-icon" src="@/assets/images/tel-icon.png" />
          <div class="tel">0731-8486 8888</div>
        </div>
        <p>周一至周五 09:00~18:30</p>
      </div>
      <div class="footer-item footer-item-md flex-1">
        <div class="footer-h1">公司信息</div>
        <div class="h1-hr"></div>
        <div class="item-mt-20">
          <p>周一至周日：09:00~18:30</p>
          <p>商业合作：0731-8486 8888</p>
          <p>人才招聘：0731-8486 8888</p>
          <p>公司：湖南中创小鱼科技有限公司</p>
          <p>地址：湖南长沙市芙蓉区芙蓉广场世茂金融大厦42楼</p>
        </div>
      </div>
      <div class="footer-item">
        <div class="footer-h1">关注我们</div>
        <div class="h1-hr"></div>
        <div class="ewm-box">
          <img class="ewm" src="@/assets/images/ewm.png" />
        </div>
        <p>添加老师微信进行咨询</p>
      </div>
    </div>
    <div class="footer-bottom">
      © 2001-2022 湖南中创小鱼科技有限公司. 版权所有 湘ICP备2022022630号-1
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.footer {
  position: relative;
  background: #222222;
}
.footer-content {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 43px;
  color: #fff;
  .footer-h1 {
    font-size: 17px;
    font-weight: 400;
    color: #f5f5f5;
    line-height: 34px;
  }
  .h1-hr {
    width: 31px;
    height: 3px;
    background: #2139d6;
  }
  .tel-box {
    margin-bottom: 39px;
    .tel-icon {
      width: 29px;
      height: 25px;
    }
    .tel {
      padding-left: 11px;
      font-size: 24px;
      font-family: Arial Rounded MT Bold;
      font-weight: 600;
      color: #f5f5f5;
      line-height: 34px;
    }
  }
  .ewm-box {
    width: 120px;
    height: 120px;
    background: #ffffff;
    border-radius: 6px;
    text-align: center;
    margin-top:26px;
    margin-bottom:4px;
    .ewm{
      width: 110px;
      height: 110px;
      margin-top: 5px;
      
    }
  }
  .footer-item-md {
    margin-left: 30px;
  }
  .footer-item p {
    font-size: 13px;
    line-height:26px;
    color: #999999;
  }
  .item-mt-20 {
    margin-top: 20px;
  }
}
.footer-bottom {
  height:40px;
  text-align: center;
  color: #666666;
  font-size: 12px;
  line-height: 40px;
}
</style>
