<template>
  <div class="nav flex flex-align-c" ref="navRef" @mouseleave="hidenavDown">
    <div class="logo-left">
      <img class="logo" alt="logo" src="@/assets/logo.png" />
    </div>
    <div class="nav-title flex-1">欢迎来到中创小鱼音乐!</div>
    <div class="menu-box"  >
      <ul class="menu flex flex-btw">
        <li
          @click="btnMenu(item)"
          v-for="(item, index) in list"
          :key="index"
          :class="
            isActiveName === item.name ? 'isActive menu-item' : 'menu-item'
          "
          @mouseenter="showDropDown(item.name)"
        >
          <router-link :to="item.link">{{ item.menu }}</router-link>
          <transition name="dropdown">
            <div
              v-show="(isShow && isActiveName === item.name)"
              class="dropdown-content"
              @mouseleave="hideDropDown"
            >
              <div class="dropdown-menu">
                <div
                  class="menuItem"
                  v-for="(item, index) in analog"
                  :key="index"
                >
                  {{ item }}
                </div>
              </div>
            </div>
          </transition>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isActiveName: "home",
      list: [
        { menu: "主页", link: "/home", name: "home" },
        { menu: "体系", link: "/system", name: "system" },
        { menu: "师资", link: "/teacher", name: "teacher" },
        { menu: "环境", link: "/environment", name: "environment" },
        // { menu: "动态", link: "/about" ,name:'about'},
      ],
      analog: ["小鱼音乐商城", "小鱼器乐商城", "小鱼生活商城"],
      isShow: false,
      navTop: 0,
    };
  },
  watch:{
    $route(){
      this.isActiveName = this.$route.name;
    }
  },
  mounted() {
    console.log("this.$route.name ", this.$route.name);
    this.isActiveName = this.$route.name;
    this.navTop =
      this.$refs.navRef.getBoundingClientRect().top +
      this.$refs.navRef.offsetHeight;
  },
  methods: {
    btnMenu(item) {
      // this.isActiveNum = index;
      this.isActiveName = item.name;
    },
    showDropDown(val) {
      this.isActiveName = val
      if (val === "system") {
        if (!this.isShow) this.isShow = true;
        this.analog = ["小鱼音乐商城", "小鱼器乐商城", "小鱼生活商城"];
      }else{
        this.isShow = false;
      }
    },
    hideDropDown(e) {
      // e.pageY：鼠标指针相对页面的偏移量
      if (this.isShow && e.pageY >= this.navTop) this.isShow = false;
      this.isActiveName = this.$route.name;
    },
    hidenavDown(e){
      this.isActiveName = this.$route.name;
      if (this.isShow && e.pageY >= this.navTop) this.isShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.nav {
  width: 100%;
  height: 70px;
  background: #333333;
  color: #fff;
  padding-left: 36px;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  z-index: 99;

  .logo {
    width: 195px;
    height: auto;
  }
  .nav-title {
    margin-left: 30px;
    font-size:16px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 25px;
    letter-spacing:1px;
  }
  .menu-box {
    margin-right: 360px;
    .menu {
      width:550px;
      .menu-item a {
        color: #fff;
      }
      .menu-item {
        position: relative;
      }
      .isActive::after {
        position: absolute;
        bottom: -11px;
        left: 50%;
        transform: translateX(-50%);
        content: "";
        width: 100%;
        width: 28px;
        height: 3px;
        background: #ffffff;
      }
    }
  }
}
.dropdown-content {
  position: absolute;
  width: 150px; // 拉满
  left: 50%;
  top: 40px;
  transform: translateX(-50%);
  overflow: hidden;
  .dropdown-menu {
    padding: 10px 8px 15px;
    background: #333333;
    color: #fff;
    border-radius: 4px;
    /* animation: menu 0.6s; */
    .menuItem {
      width: 100%;
      white-space: nowrap;
      padding: 10px 16px;
      font-size: 14px;
      color: #fff;
      cursor: pointer;
      transition: all 0.3s;
      border-radius: 4px;
      box-sizing: border-box;
      &:hover {
        background-color: #0560ff;
      }
    }
  }
}
</style>
