<template>
  <div>
    <div class="banner">
      <img class="banner w-100" src="@/assets/images/system/banner.jpg" />
    </div>
    <!-- 第一部分 -->
    <div class="content-main-1">
      <div class="content-box">
        <div class="title-h1">
          <img class="title-img" src="@/assets/images/system/title-bg-yy.png" />
        </div>
        <div class="title-h2 text-align-c">
          <span class="font-24">有调性•有梦想•爱生活</span>
        </div>

        <div class="main-1 flex flex-wrap flex-btw">
          <div class="main-1-item">
            <div class="item-num">01</div>
            <div class="item-title">《声乐》</div>
            <div class="item-intro">
              用音乐创造愉悦生活，系统声乐课程分为基础阶(42课时)+高阶(38课时)两个部分，满足从0基础到进阶专业提升的歌唱需求。采用视频学习基础知识点+老师一对一辅导+个性化学习规划方式，不用担心时间问题又能有老师针对性辅导，让小白从五音不全到能够识谱歌唱，让唱歌平平无奇的声音到专业好听有情感共鸣。
            </div>
          </div>
          <div class="main-1-item">
            <div class="item-num">02</div>
            <div class="item-title">《吉他》</div>
            <div class="item-intro">
              吉他弹唱的魅力无人能挡，小鱼吉他课程分为兴趣和专业提升，了解简单的乐理知识，轻松入门上手，边弹边唱，系统学习吉他课程也可以接触指弹，充分提升展示个人音乐素养。
            </div>
          </div>
          <div class="main-1-item">
            <div class="item-num">03</div>
            <div class="item-title">《钢琴》</div>
            <div class="item-intro">
              钢琴作为乐器之王，学习钢琴有助于提升综合音乐能力。小鱼钢琴课程从兴趣到全能班，满足不同基础同学的学习需求，了解乐理，以及伴奏和弦织体，对于喜欢的歌曲进行弹唱。
            </div>
          </div>
          <div class="main-1-item">
            <div class="item-num">04</div>
            <div class="item-title">《乐器商城》</div>
            <div class="item-intro">
              乐器商城应有尽有，主要针对学员的日常学习需求，有各类乐器可以给到大家福利。乐器商城经综合挑选，目前上架主推两款吉他和钢琴，除了基础优惠之外，目前限时活动福利赠送入门学习直播课！即买即学，不担心乐器闲置！
            </div>
          </div>
        </div>
      </div>
      <div class="gopage-btn" v-wave>进入小鱼音乐商城</div>
    </div>
    <!-- 第二部分 -->
    <div class="content-main-2">
      <div class="content-box">
        <div class="title-h1">
          <img class="title-img" src="@/assets/images/system/title-bg-qy.png" />
        </div>
        <div class="title-h2 text-align-c">
          <span class="font-24 font-white">有调性•有梦想•爱生活</span>
        </div>
        <div class="main-2 flex flex-btw">
          <img class="main-2-img" src="@/assets/images/system/main-2-1.png" />
          <img class="main-2-img" src="@/assets/images/system/main-2-2.png" />
          <img class="main-2-img" src="@/assets/images/system/main-2-3.png" />
        </div>
        <p class="main-2-txt">
          小鱼器乐目前主要架设《钢琴即兴伴奏》《吉他弹唱》两类课程，根据不同的需求，不同的基础，对应不同内容的学习，致力于帮助学员，学好乐器，更好的进行演奏演唱。
        </p>
        <div class="gopage-btn" v-wave>进入小鱼器乐商城</div>
      </div>
    </div>
    <!-- 第三部分 -->
    <div class="content-main-3">
      <img class="main-3-bg" src="@/assets/images/system/main-3-bg.png" />
      <div class="content-box">
        <div class="title-h1">
          <img class="title-img" src="@/assets/images/system/title-bg-sh.png" />
        </div>
        <div class="title-h2 text-align-c">
          <span class="font-24">有调性•有梦想•爱生活</span>
        </div>
        <div class="main-3 flex flex-wrap flex-btw">
          <div class="goods-item" v-for="(item, index) in goods" :key="index">
            <img class="goods-pic" :src="item.pic" />
            <div class="goods-name">{{ item.name }}</div>
          </div>
        </div>
        <div class="gopage-btn" v-wave>进入小鱼生活商城</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      goods: [
        {
          pic: require("@/assets/images/system/goods-1.png"),
          name: "小鱼手提袋",
        },
        {
          pic: require("@/assets/images/system/goods-2.png"),
          name: "小鱼胸针",
        },
        {
          pic: require("@/assets/images/system/goods-3.png"),
          name: "小鱼马克杯(蓝）",
        },
        {
          pic: require("@/assets/images/system/goods-4.png"),
          name: "小鱼抱枕",
        },
        {
          pic: require("@/assets/images/system/goods-5.png"),
          name: "小鱼马克杯(白",
        },
        {
          pic: require("@/assets/images/system/goods-6.png"),
          name: "小鱼卫衣",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.banner {
  width: 100%;
  height: auto;
}
.title-h1 {
  text-align: center;
  .title-img {
    width: 205px;
    height: auto;
    margin-bottom:4px;
  }
}
.title-h2 {
  font-size:20px;
  font-family: Microsoft YaHei;
  font-weight:500;
  color: #262b2d;
  line-height: 34px;
  letter-spacing:5px;
}
.content-main-1 {
  margin-top: 42px;
  .main-1 {
    width: 995px;
    margin: 40px auto;
    .main-1-item {
      width: 399px;
      border-top: 10px solid #333333;
      padding-top:12px;
      padding-bottom:24px;
      .item-num {
        font-size: 86px;
        font-family: Futura Md BT;
        font-weight: bold;
        color: #333333;
      }
      .item-title {
        margin-top: 22px;
        font-size:28px;
        font-weight: bold;
        color: #333333;
        line-height: 34px;
      }
      .item-intro {
        margin-top: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #2d3137;
        line-height:26px;
      }
    }
    .main-1-item:hover {
      border-top: 10px solid #055ffb;
      .item-num,
      .item-title {
        color: #055ffb;
      }
    }
  }
}
.gopage-btn {
  width:160px;
  height: 38px;
  background: linear-gradient(269deg, #0560ff, #0060ff);
  border-radius: 25px;
  margin:40px auto 50px;
  color: #ffffff;
  line-height: 38px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}
.content-main-2 {
  width: 100%;
  // height: 880px;
  background: #1c202c;
  padding-top:85px;
  padding-bottom:2px;
  box-sizing: border-box;
  .main-2 {
    margin-top:70px;
    .main-2-img {
      width: 380px;
      height: 250px;
    }
  }
  .main-2-txt {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 26px;
    text-align: center;
    padding-top:34px;
    margin-bottom:60px;
  }
}
.content-main-3 {
  padding-top: 51px;
  position: relative;
  .main-3-bg {
    position: absolute;
    width: 1663px;
    height: 413px;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    z-index: 1;
  }
  .main-3 {
    width: 928px;
    margin: 90px auto 0;
    .goods-item {
      width: 432px;
      border-radius: 10px;
      z-index: 2;
      text-align: center;
      margin-bottom: 55px;
      .goods-pic {
        width: 432px;
        height: 270px;
      }
      .goods-name {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #262b2d;
        line-height: 34px;
        margin-top:16px;
      }
    }
  }
}
</style>
